define("discourse/plugins/automation/admin/components/fields/da-choices-field", ["exports", "@ember/helper", "I18n", "select-kit/components/combo-box", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/component", "@ember/template-factory"], function (_exports, _helper, _I18n, _comboBox, _daBaseField, _daFieldDescription, _daFieldLabel, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _ChoicesField;
  class ChoicesField extends _daBaseField.default {
    get replacedContent() {
      return (this.args.field.extra.content || []).map(r1 => {
        return {
          id: r1.id,
          name: r1.translated_name || _I18n.default.t(r1.name)
        };
      });
    }
  }
  _exports.default = ChoicesField;
  _ChoicesField = ChoicesField;
  (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="field control-group">
        <DAFieldLabel @label={{@label}} @field={{@field}} />
  
        <div class="controls">
          <ComboBox
            @value={{@field.metadata.value}}
            @content={{this.replacedContent}}
            @onChange={{this.mutValue}}
            @options={{hash
              allowAny=false
              clearable=true
              disabled=@field.isDisabled
            }}
          />
  
          <DAFieldDescription @description={{@description}} />
        </div>
      </div>
    
  */
  {
    "id": "5/f1PQyC",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"field control-group\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\"],[[30,2,[\"metadata\",\"value\"]],[30,0,[\"replacedContent\"]],[30,0,[\"mutValue\"]],[28,[32,2],null,[[\"allowAny\",\"clearable\",\"disabled\"],[false,true,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n\\n        \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-choices-field.js",
    "scope": () => [_daFieldLabel.default, _comboBox.default, _helper.hash, _daFieldDescription.default],
    "isStrictMode": true
  }), _ChoicesField);
});