import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _NewListHeaderControlsWrapper;
import Component from "@glimmer/component";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import NewListHeaderControls from "discourse/components/topic-list/new-list-header-controls";
import raw from "discourse/helpers/raw";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let NewListHeaderControlsWrapper = (_class = (_NewListHeaderControlsWrapper = class NewListHeaderControlsWrapper extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
  }
  click(e1) {
    const target1 = e1.target;
    if (target1.closest("button.topics-replies-toggle.--all")) {
      this.args.changeNewListSubset(null);
    } else if (target1.closest("button.topics-replies-toggle.--topics")) {
      this.args.changeNewListSubset("topics");
    } else if (target1.closest("button.topics-replies-toggle.--replies")) {
      this.args.changeNewListSubset("replies");
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.currentUser.use_glimmer_topic_list}}
      <div class="topic-replies-toggle-wrapper">
        <NewListHeaderControls
          @current={{@current}}
          @newRepliesCount={{@newRepliesCount}}
          @newTopicsCount={{@newTopicsCount}}
          @noStaticLabel={{true}}
          @changeNewListSubset={{@changeNewListSubset}}
        />
      </div>
    {{else}}
      <div
        {{! template-lint-disable no-invalid-interactive }}
        {{on "click" this.click}}
        class="topic-replies-toggle-wrapper"
      >
        {{raw
          "list/new-list-header-controls"
          current=@current
          newRepliesCount=@newRepliesCount
          newTopicsCount=@newTopicsCount
          noStaticLabel=true
        }}
      </div>
    {{/if}}
  
*/
{
  "id": "5DmC4/DJ",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"use_glimmer_topic_list\"]],[[[1,\"      \"],[10,0],[14,0,\"topic-replies-toggle-wrapper\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@current\",\"@newRepliesCount\",\"@newTopicsCount\",\"@noStaticLabel\",\"@changeNewListSubset\"],[[30,1],[30,2],[30,3],true,[30,4]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,0],[24,0,\"topic-replies-toggle-wrapper\"],[4,[32,1],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n        \"],[1,[28,[32,2],[\"list/new-list-header-controls\"],[[\"current\",\"newRepliesCount\",\"newTopicsCount\",\"noStaticLabel\"],[[30,1],[30,2],[30,3],true]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@current\",\"@newRepliesCount\",\"@newTopicsCount\",\"@changeNewListSubset\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/new-list-header-controls-wrapper.js",
  "scope": () => [NewListHeaderControls, on, raw],
  "isStrictMode": true
}), _NewListHeaderControlsWrapper), _NewListHeaderControlsWrapper), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "click", [action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class);
export { NewListHeaderControlsWrapper as default };