define("discourse/plugins/automation/admin/components/fields/da-base-field", ["exports", "@glimmer/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let BaseField = _exports.default = (_class = class BaseField extends _component.default {
    get displayPlaceholders() {
      return this.args.placeholders?.length && this.args.field?.acceptsPlaceholders;
    }
    mutValue(newValue) {
      this.args.field.metadata.value = newValue;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "mutValue", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "mutValue"), _class.prototype)), _class);
});