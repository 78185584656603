define("discourse/plugins/automation/admin/components/fields/da-key-value-field", ["exports", "@glimmer/tracking", "@ember/helper", "@ember/object", "discourse/components/d-button", "discourse/components/modal/json-schema-editor", "I18n", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/component", "@ember/template-factory"], function (_exports, _tracking, _helper, _object, _dButton, _jsonSchemaEditor, _I18n, _daBaseField, _daFieldDescription, _daFieldLabel, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _KeyValueField;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let KeyValueField = _exports.default = (_class = (_KeyValueField = class KeyValueField extends _daBaseField.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "showJsonEditorModal", _descriptor, this);
      _defineProperty(this, "jsonSchema", {
        type: "array",
        uniqueItems: true,
        items: {
          type: "object",
          title: "group",
          properties: {
            key: {
              type: "string"
            },
            value: {
              type: "string",
              format: "textarea"
            }
          }
        }
      });
    }
    get value() {
      return this.args.field.metadata.value || '[{"key":"example","value":"You posted {{key}}"}]';
    }
    get keyCount() {
      if (this.args.field.metadata.value) {
        return JSON.parse(this.value).length;
      }
      return 0;
    }
    get showJsonModalLabel() {
      if (this.keyCount === 0) {
        return _I18n.default.t("discourse_automation.fields.key_value.label_without_count");
      } else {
        return _I18n.default.t("discourse_automation.fields.key_value.label_with_count", {
          count: this.keyCount
        });
      }
    }
    handleValueChange(value1) {
      if (value1 !== this.args.field.metadata.value) {
        this.mutValue(value1);
        this.args.saveAutomation();
      }
    }
    openModal() {
      this.showJsonEditorModal = true;
    }
    closeModal() {
      this.showJsonEditorModal = false;
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field key-value-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <DButton class="configure-btn" @action={{this.openModal}}>
              {{this.showJsonModalLabel}}
            </DButton>
  
            {{#if this.showJsonEditorModal}}
              <ModalJsonSchemaEditor
                @model={{hash
                  value=this.value
                  updateValue=this.handleValueChange
                  settingName=@label
                  jsonSchema=this.jsonSchema
                }}
                @closeModal={{this.closeModal}}
              />
            {{/if}}
  
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      </section>
    
  */
  {
    "id": "WU6hREdJ",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field key-value-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],[[24,0,\"configure-btn\"]],[[\"@action\"],[[30,0,[\"openModal\"]]]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"showJsonModalLabel\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"showJsonEditorModal\"]],[[[1,\"            \"],[8,[32,2],null,[[\"@model\",\"@closeModal\"],[[28,[32,3],null,[[\"value\",\"updateValue\",\"settingName\",\"jsonSchema\"],[[30,0,[\"value\"]],[30,0,[\"handleValueChange\"]],[30,1],[30,0,[\"jsonSchema\"]]]]],[30,0,[\"closeModal\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n          \"],[8,[32,4],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-key-value-field.js",
    "scope": () => [_daFieldLabel.default, _dButton.default, _jsonSchemaEditor.default, _helper.hash, _daFieldDescription.default],
    "isStrictMode": true
  }), _KeyValueField), _KeyValueField), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showJsonEditorModal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleValueChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleValueChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class);
});