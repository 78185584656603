import avatar from "discourse/helpers/avatar";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const PostersColumn = setComponentTemplate(createTemplateFactory(
/*
  
  <td class="posters topic-list-data">
    {{#each @posters as |poster|}}
      {{#if poster.moreCount}}
        <a class="posters-more-count">{{poster.moreCount}}</a>
      {{else}}
        <a
          href={{poster.user.path}}
          data-user-card={{poster.user.username}}
          class={{poster.extraClasses}}
        >{{avatar
            poster
            avatarTemplatePath="user.avatar_template"
            usernamePath="user.username"
            namePath="user.name"
            imageSize="small"
          }}</a>
      {{/if}}
    {{/each}}
  </td>

*/
{
  "id": "hUSyWgus",
  "block": "[[[1,\"\\n  \"],[10,\"td\"],[14,0,\"posters topic-list-data\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[41,[30,2,[\"moreCount\"]],[[[1,\"        \"],[10,3],[14,0,\"posters-more-count\"],[12],[1,[30,2,[\"moreCount\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,3],[15,6,[30,2,[\"user\",\"path\"]]],[15,\"data-user-card\",[30,2,[\"user\",\"username\"]]],[15,0,[30,2,[\"extraClasses\"]]],[12],[1,[28,[32,0],[[30,2]],[[\"avatarTemplatePath\",\"usernamePath\",\"namePath\",\"imageSize\"],[\"user.avatar_template\",\"user.username\",\"user.name\",\"small\"]]]],[13],[1,\"\\n\"]],[]]]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"@posters\",\"poster\"],false,[\"each\",\"-track-array\",\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/posters-column.js",
  "scope": () => [avatar],
  "isStrictMode": true
}), templateOnly());
export default PostersColumn;