define("discourse/plugins/automation/admin/controllers/admin-plugins-discourse-automation-edit", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/runloop", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n"], function (_exports, _controller, _object, _computed, _runloop, _service, _ajax, _ajaxError, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AutomationEdit = _exports.default = (_dec = (0, _computed.reads)("model.automation"), _dec2 = (0, _computed.filterBy)("automationForm.fields", "targetType", "script"), _dec3 = (0, _computed.filterBy)("automationForm.fields", "targetType", "trigger"), _dec4 = (0, _object.computed)("model.automation.next_pending_automation_at"), (_class = class AutomationEdit extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "dialog", _descriptor, this);
      _defineProperty(this, "error", null);
      _defineProperty(this, "isUpdatingAutomation", false);
      _defineProperty(this, "isTriggeringAutomation", false);
      _initializerDefineProperty(this, "automation", _descriptor2, this);
      _initializerDefineProperty(this, "scriptFields", _descriptor3, this);
      _initializerDefineProperty(this, "triggerFields", _descriptor4, this);
    }
    get nextPendingAutomationAtFormatted() {
      const date = this.model?.automation?.next_pending_automation_at;
      if (date) {
        return moment(date).format("LLLL");
      }
    }
    saveAutomation() {
      this.setProperties({
        error: null,
        isUpdatingAutomation: true
      });
      return (0, _ajax.ajax)(`/admin/plugins/discourse-automation/automations/${this.model.automation.id}.json`, {
        type: "PUT",
        data: JSON.stringify({
          automation: this.automationForm
        }),
        dataType: "json",
        contentType: "application/json"
      }).then(() => {
        this.send("refreshRoute");
      }).catch(e => this._showError(e)).finally(() => {
        this.set("isUpdatingAutomation", false);
      });
    }
    onChangeTrigger(id) {
      if (this.automationForm.trigger && this.automationForm.trigger !== id) {
        this._confirmReset(() => {
          (0, _object.set)(this.automationForm, "trigger", id);
          this.saveAutomation();
        });
      } else if (!this.automationForm.trigger) {
        (0, _object.set)(this.automationForm, "trigger", id);
        this.saveAutomation();
      }
    }
    onManualAutomationTrigger(id) {
      this._confirmTrigger(() => {
        this.set("isTriggeringAutomation", true);
        return (0, _ajax.ajax)(`/automations/${id}/trigger.json`, {
          type: "post"
        }).catch(e => this.set("error", (0, _ajaxError.extractError)(e))).finally(() => {
          this.set("isTriggeringAutomation", false);
        });
      });
    }
    onChangeScript(id) {
      if (this.automationForm.script !== id) {
        this._confirmReset(() => {
          (0, _object.set)(this.automationForm, "script", id);
          this.saveAutomation();
        });
      }
    }
    _confirmReset(callback) {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("discourse_automation.confirm_automation_reset"),
        didConfirm: () => {
          return callback && callback();
        }
      });
    }
    _confirmTrigger(callback) {
      this.dialog.yesNoConfirm({
        message: _I18n.default.t("discourse_automation.confirm_automation_trigger"),
        didConfirm: () => {
          return callback && callback();
        }
      });
    }
    _showError(error) {
      this.set("error", (0, _ajaxError.extractError)(error));
      (0, _runloop.schedule)("afterRender", () => {
        window.scrollTo(0, 0);
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "automation", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "scriptFields", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "triggerFields", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "nextPendingAutomationAtFormatted", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "nextPendingAutomationAtFormatted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveAutomation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveAutomation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeTrigger", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeTrigger"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onManualAutomationTrigger", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onManualAutomationTrigger"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeScript", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeScript"), _class.prototype)), _class));
});