define("discourse/plugins/discourse-gamification/initializers/admin-plugin-configuration-nav", ["exports", "discourse/lib/admin-plugin-config-nav", "discourse/lib/plugin-api"], function (_exports, _adminPluginConfigNav, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-gamification-admin-plugin-configuration-nav",
    initialize(container) {
      const currentUser = container.lookup("service:current-user");
      if (!currentUser || !currentUser.admin) {
        return;
      }
      (0, _pluginApi.withPluginApi)("1.1.0", api => {
        api.addAdminPluginConfigurationNav("discourse-gamification", _adminPluginConfigNav.PLUGIN_NAV_MODE_TOP, [{
          label: "gamification.leaderboard.title",
          route: "adminPlugins.show.discourse-gamification-leaderboards"
        }]);
      });
    }
  };
});