import ClassicComponent, { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const empty = setComponentTemplate(createTemplateFactory(
/*
  
  {{! template-lint-disable no-yield-only}}{{yield}}

*/
{
  "id": "DyUIISDx",
  "block": "[[[1,\"\\n  \"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/helpers/element.js",
  "isStrictMode": true
}), templateOnly());
const shortcuts = {
  div: setComponentTemplate(createTemplateFactory(
  /*
    <div ...attributes>{{yield}}</div>
  */
  {
    "id": "0CMDnJf1",
    "block": "[[[11,0],[17,1],[12],[18,2,null],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/helpers/element.js",
    "isStrictMode": true
  }), templateOnly()),
  span: setComponentTemplate(createTemplateFactory(
  /*
    <span ...attributes>{{yield}}</span>
  */
  {
    "id": "sIZx3Mbo",
    "block": "[[[11,1],[17,1],[12],[18,2,null],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/helpers/element.js",
    "isStrictMode": true
  }), templateOnly()),
  form: setComponentTemplate(createTemplateFactory(
  /*
    <form ...attributes>{{yield}}</form>
  */
  {
    "id": "aHMeDlWT",
    "block": "[[[11,\"form\"],[17,1],[12],[18,2,null],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/helpers/element.js",
    "isStrictMode": true
  }), templateOnly()),
  a: setComponentTemplate(createTemplateFactory(
  /*
    <a ...attributes>{{yield}}</a>
  */
  {
    "id": "DMPGWHcK",
    "block": "[[[11,3],[17,1],[12],[18,2,null],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/helpers/element.js",
    "isStrictMode": true
  }), templateOnly()),
  button: setComponentTemplate(createTemplateFactory(
  /*
    <button ...attributes>{{yield}}</button>
  */
  {
    "id": "s28TA0CZ",
    "block": "[[[11,\"button\"],[17,1],[12],[18,2,null],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/helpers/element.js",
    "isStrictMode": true
  }), templateOnly()),
  td: setComponentTemplate(createTemplateFactory(
  /*
    <td ...attributes>{{yield}}</td>
  */
  {
    "id": "/nlPcyNB",
    "block": "[[[11,\"td\"],[17,1],[12],[18,2,null],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/helpers/element.js",
    "isStrictMode": true
  }), templateOnly())
};
/**
 * Returns a wrapper component with the given tagname, or an empty wrapper for an empty string.
 * Similar to the reference implementation of RFC389, with higher-performance shortcuts for common elements.
 *
 * Can be used directly in a template:
 *
 * ```hbs
 * {{#let (element @tagName) as |Wrapper|}}
 *   <Wrapper class="pt-10 pb-10 ps-20 box-shadow" ...attributes>
 *     Content
 *   </Wrapper>
 * {{/let}}
 * ```
 *
 * Or in js:
 *
 * ```gjs
 * class MyComponent {
 *   get wrapper(){
 *     return element(this.args.tagName);
 *   }
 *
 *   <template>
 *     <this.wrapper>
 *       Content
 *     </this.wrapper>
 *   </template>
 * }
 * ```
 */
export default function element(tagName) {
  if (typeof tagName !== "string") {
    throw new Error(`element helper only accepts string literals, you passed ${tagName}`);
  }
  if (tagName === null || tagName === undefined) {
    return null;
  } else if (tagName === "") {
    return empty;
  } else if (shortcuts[tagName]) {
    return shortcuts[tagName];
  } else {
    return setComponentTemplate(createTemplateFactory(
    /*
      
          <ClassicComponent
            @tagName={{tagName}}
            ...attributes
          >{{yield}}</ClassicComponent>
        
    */
    {
      "id": "xtqLwga7",
      "block": "[[[1,\"\\n      \"],[8,[32,0],[[17,1]],[[\"@tagName\"],[[32,1]]],[[\"default\"],[[[[18,2,null]],[]]]]],[1,\"\\n    \"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/helpers/element.js",
      "scope": () => [ClassicComponent, tagName],
      "isStrictMode": true
    }), templateOnly());
  }
}