import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const ParticipantGroups = setComponentTemplate(createTemplateFactory(
/*
  
  <div
    role="list"
    aria-label={{i18n "topic.participant_groups"}}
    class="participant-group-wrapper"
  >
    {{#each @groups as |group|}}
      <div class="participant-group">
        <a
          href={{group.url}}
          data-group-card={{group.name}}
          class="user-group trigger-group-card"
        >
          {{icon "users"}}
          {{group.name}}
        </a>
      </div>
    {{/each}}
  </div>

*/
{
  "id": "A/QzGagl",
  "block": "[[[1,\"\\n  \"],[10,0],[14,\"role\",\"list\"],[15,\"aria-label\",[28,[32,0],[\"topic.participant_groups\"],null]],[14,0,\"participant-group-wrapper\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"participant-group\"],[12],[1,\"\\n        \"],[10,3],[15,6,[30,2,[\"url\"]]],[15,\"data-group-card\",[30,2,[\"name\"]]],[14,0,\"user-group trigger-group-card\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"users\"],null]],[1,\"\\n          \"],[1,[30,2,[\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[\"@groups\",\"group\"],false,[\"each\",\"-track-array\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/participant-groups.js",
  "scope": () => [i18n, icon],
  "isStrictMode": true
}), templateOnly());
export default ParticipantGroups;