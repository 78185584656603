import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16;
/* import __COLOCATED_TEMPLATE__ from './container.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { headerOffset } from "discourse/lib/offset-calculator";
import { actionDescriptionHtml } from "discourse/widgets/post-small-action";
import { bind, debounce } from "discourse-common/utils/decorators";
import domUtils from "discourse-common/utils/dom-utils";
import I18n from "discourse-i18n";
import TEMPLATE from "./container.hbs";
import { setComponentTemplate } from "@ember/component";
export const SCROLLER_HEIGHT = 50;
const DEFAULT_MIN_SCROLLAREA_HEIGHT = 170;
const DEFAULT_MAX_SCROLLAREA_HEIGHT = 300;
const LAST_READ_HEIGHT = 20;
let desktopMinScrollAreaHeight = DEFAULT_MIN_SCROLLAREA_HEIGHT;
let desktopMaxScrollAreaHeight = DEFAULT_MAX_SCROLLAREA_HEIGHT;
export function setDesktopScrollAreaHeight() {
  let height = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    min: DEFAULT_MIN_SCROLLAREA_HEIGHT,
    max: DEFAULT_MAX_SCROLLAREA_HEIGHT
  };
  desktopMinScrollAreaHeight = height.min;
  desktopMaxScrollAreaHeight = height.max;
}
let TopicTimelineScrollArea = (_dec = debounce(50), (_class = class TopicTimelineScrollArea extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "appEvents", _descriptor, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    _initializerDefineProperty(this, "currentUser", _descriptor3, this);
    _initializerDefineProperty(this, "showButton", _descriptor4, this);
    _initializerDefineProperty(this, "current", _descriptor5, this);
    _initializerDefineProperty(this, "percentage", _descriptor6, this);
    _initializerDefineProperty(this, "total", _descriptor7, this);
    _initializerDefineProperty(this, "date", _descriptor8, this);
    _initializerDefineProperty(this, "lastReadPercentage", _descriptor9, this);
    _initializerDefineProperty(this, "lastRead", _descriptor10, this);
    _initializerDefineProperty(this, "lastReadTop", _descriptor11, this);
    _initializerDefineProperty(this, "before", _descriptor12, this);
    _initializerDefineProperty(this, "after", _descriptor13, this);
    _initializerDefineProperty(this, "timelineScrollareaStyle", _descriptor14, this);
    _initializerDefineProperty(this, "dragging", _descriptor15, this);
    _initializerDefineProperty(this, "excerpt", _descriptor16, this);
    _defineProperty(this, "intersectionObserver", null);
    _defineProperty(this, "scrollareaElement", null);
    _defineProperty(this, "scrollerElement", null);
    _defineProperty(this, "dragOffset", null);
    if (!this.args.mobileView) {
      // listen for scrolling event to update timeline
      this.appEvents.on("topic:current-post-scrolled", this.postScrolled);
      // listen for composer sizing changes to update timeline
      this.appEvents.on("composer:opened", this.calculatePosition);
      this.appEvents.on("composer:resized", this.calculatePosition);
      this.appEvents.on("composer:closed", this.calculatePosition);
      this.appEvents.on("post-stream:posted", this.calculatePosition);
    }
    this.intersectionObserver = new IntersectionObserver(entries => {
      for (const entry of entries) {
        const bounds = entry.boundingClientRect;
        if (entry.target.id === "topic-bottom") {
          this.topicBottom = bounds.y + window.scrollY;
        } else {
          this.topicTop = bounds.y + window.scrollY;
        }
      }
    });
    const elements = [document.querySelector(".container.posts"), document.querySelector("#topic-bottom")];
    for (let i = 0; i < elements.length; i++) {
      this.intersectionObserver.observe(elements[i]);
    }
    this.calculatePosition();
    this.dockCheck();
  }
  get displaySummary() {
    return this.siteSettings.summary_timeline_button && !this.args.fullScreen && this.args.model.has_summary && !this.args.model.postStream.summary;
  }
  get displayTimeLineScrollArea() {
    if (this.args.mobileView) {
      return true;
    }
    if (this.total === 1) {
      const postsWrapper = document.querySelector(".posts-wrapper");
      if (postsWrapper && postsWrapper.offsetHeight < 1000) {
        return false;
      }
    }
    return true;
  }
  get canCreatePost() {
    return this.args.model.details?.can_create_post;
  }
  get topicTitle() {
    return htmlSafe(this.args.mobileView ? this.args.model.fancyTitle : "");
  }
  get showTags() {
    return this.siteSettings.tagging_enabled && this.args.model.tags?.length > 0;
  }
  get style() {
    return htmlSafe(`height: ${this.scrollareaHeight}px`);
  }
  get beforePadding() {
    return htmlSafe(`height: ${this.before}px`);
  }
  get afterPadding() {
    return htmlSafe(`height: ${this.after}px`);
  }
  get showDockedButton() {
    return !this.args.mobileView && this.hasBackPosition && !this.showButton;
  }
  get hasBackPosition() {
    return this.lastRead && this.lastRead > 3 && this.lastRead > this.current && Math.abs(this.lastRead - this.current) > 3 && Math.abs(this.lastRead - this.total) > 1 && this.lastRead !== this.total;
  }
  get lastReadStyle() {
    return htmlSafe(`height: ${LAST_READ_HEIGHT}px; top: ${this.topPosition}px`);
  }
  get topPosition() {
    const bottom = this.scrollareaHeight - LAST_READ_HEIGHT / 2;
    return this.lastReadTop > bottom ? bottom : this.lastReadTop;
  }
  get scrollareaHeight() {
    const composerHeight = document.getElementById("reply-control").offsetHeight || 0,
      headerHeight = document.querySelector(".d-header")?.offsetHeight || 0;

    // scrollarea takes up about half of the timeline's height
    const availableHeight = (window.innerHeight - composerHeight - headerHeight) / 2;
    const minHeight = this.args.mobileView ? DEFAULT_MIN_SCROLLAREA_HEIGHT : desktopMinScrollAreaHeight;
    const maxHeight = this.args.mobileView ? DEFAULT_MAX_SCROLLAREA_HEIGHT : desktopMaxScrollAreaHeight;
    return Math.max(minHeight, Math.min(availableHeight, maxHeight));
  }
  get startDate() {
    return timelineDate(this.args.model.createdAt);
  }
  get nowDateOptions() {
    return {
      customTitle: I18n.t("topic_entrance.sr_jump_bottom_button"),
      addAgo: true,
      defaultFormat: timelineDate
    };
  }
  get nowDate() {
    return this.args.model.get("last_posted_at") || this.args.model.get("created_at");
  }
  get lastReadHeight() {
    return Math.round(this.lastReadPercentage * this.scrollareaHeight);
  }
  calculatePosition() {
    this.timelineScrollareaStyle = htmlSafe(`height: ${this.scrollareaHeight}px`);
    const topic = this.args.model;
    const postStream = topic.postStream;
    this.total = postStream.filteredPostsCount;
    this.scrollPosition = this.clamp(Math.floor(this.total * this.percentage), 0, this.total) + 1;
    this.current = this.clamp(this.scrollPosition, 1, this.total);
    const daysAgo = postStream.closestDaysAgoFor(this.current);
    let date;
    if (daysAgo === undefined) {
      const post = postStream.posts.findBy("id", postStream.stream[this.current]);
      if (post) {
        date = new Date(post.created_at);
      }
    } else if (daysAgo !== null) {
      date = new Date();
      date.setDate(date.getDate() - daysAgo || 0);
    } else {
      date = null;
    }
    this.date = date;
    const lastReadNumber = topic.last_read_post_number;
    const lastReadId = topic.last_read_post_id;
    if (lastReadId && lastReadNumber) {
      const idx = postStream.stream.indexOf(lastReadId) + 1;
      this.lastRead = idx;
      this.lastReadPercentage = this._percentFor(topic, idx);
    }
    if (this.position !== this.scrollPosition) {
      this.position = this.scrollPosition;
      this.updateScrollPosition(this.current);
    }
    this.before = this.scrollareaRemaining() * this.percentage;
    this.after = this.scrollareaHeight - this.before - SCROLLER_HEIGHT;
    if (this.percentage === null) {
      return;
    }
    if (this.hasBackPosition) {
      this.lastReadTop = Math.round(this.lastReadPercentage * this.scrollareaHeight);
      this.showButton = this.before + SCROLLER_HEIGHT - 5 < this.lastReadTop || this.before > this.lastReadTop + 25;
    }
  }
  updateScrollPosition(scrollPosition) {
    // only ran on mobile
    if (!this.args.fullscreen) {
      return;
    }
    const stream = this.args.model.postStream;
    if (!this.position === scrollPosition) {
      return;
    }

    // we have an off by one, stream is zero based,
    stream.excerpt(scrollPosition - 1).then(info => {
      if (info && this.position === scrollPosition) {
        let excerpt = "";
        if (info.username) {
          excerpt = "<span class='username'>" + info.username + ":</span> ";
        }
        if (info.excerpt) {
          this.excerpt = excerpt + info.excerpt;
        } else if (info.action_code) {
          this.excerpt = `${excerpt} ${actionDescriptionHtml(info.action_code, info.created_at, info.username)}`;
        }
      }
    });
  }
  updatePercentage(e) {
    const currentCursorY = e.pageY || e.touches[0].pageY;
    const desiredScrollerCentre = currentCursorY - this.dragOffset;
    const areaTop = domUtils.offset(this.scrollareaElement).top;
    const areaHeight = this.scrollareaElement.offsetHeight;
    const scrollerHeight = this.scrollerElement.offsetHeight;

    // The range of possible positions for the centre of the scroller
    const scrollableTop = areaTop + scrollerHeight / 2;
    const scrollableHeight = areaHeight - scrollerHeight;
    this.percentage = this.clamp(parseFloat(desiredScrollerCentre - scrollableTop) / scrollableHeight);
    this.commit();
  }
  didStartDrag(event) {
    const y = event.pageY || event.touches[0].pageY;
    const scrollerCentre = domUtils.offset(this.scrollerElement).top + this.scrollerElement.offsetHeight / 2;
    this.dragOffset = y - scrollerCentre;
    this.dragging = true;
  }
  dragMove(event) {
    event.stopPropagation();
    event.preventDefault();
    this.updatePercentage(event);
  }
  didEndDrag() {
    this.dragging = false;
    this.dragOffset = null;
    this.commit();
  }
  postScrolled(e) {
    this.current = e.postIndex;
    this.percentage = e.percent;
    this.calculatePosition();
    this.dockCheck();
  }
  goBack() {
    this.args.jumpToIndex(this.lastRead);
  }
  dockCheck() {
    const timeline = document.querySelector(".timeline-container");
    const timelineHeight = timeline && timeline.offsetHeight || 400;
    const prevDockAt = this.dockAt;
    const positionTop = headerOffset() + window.pageYOffset;
    const currentPosition = positionTop + timelineHeight;
    this.dockBottom = false;
    if (positionTop < this.topicTop) {
      this.dockAt = parseInt(this.topicTop, 10);
    } else if (currentPosition > this.topicBottom) {
      this.dockAt = parseInt(this.topicBottom - timelineHeight, 10);
      this.dockBottom = true;
      if (this.dockAt < 0) {
        this.dockAt = 0;
      }
    } else {
      this.dockAt = null;
    }
    if (this.dockAt !== prevDockAt) {
      if (this.dockAt) {
        this.args.setDocked(true);
        if (this.dockBottom) {
          this.args.setDockedBottom(true);
        }
      } else {
        this.args.setDocked(false);
        this.args.setDockedBottom(false);
      }
    }
  }
  commit() {
    this.calculatePosition();
    if (!this.dragging) {
      if (this.current === this.scrollPosition) {
        this.args.jumpToIndex(this.current);
      } else {
        this.args.jumpEnd();
      }
    }
  }
  clamp(p) {
    let min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.0;
    let max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1.0;
    return Math.max(Math.min(p, max), min);
  }
  scrollareaRemaining() {
    return this.scrollareaHeight - SCROLLER_HEIGHT;
  }
  willDestroy() {
    super.willDestroy(...arguments);
    if (!this.args.mobileView) {
      this.intersectionObserver?.disconnect();
      this.intersectionObserver = null;
      this.appEvents.off("composer:opened", this.calculatePosition);
      this.appEvents.off("composer:resized", this.calculatePosition);
      this.appEvents.off("composer:closed", this.calculatePosition);
      this.appEvents.off("topic:current-post-scrolled", this.postScrolled);
      this.appEvents.off("post-stream:posted", this.calculatePosition);
    }
  }
  _percentFor(topic, postIndex) {
    const total = topic.postStream.filteredPostsCount;
    switch (postIndex) {
      // if first post, no top padding
      case 0:
        return 0;
      // if last, no bottom padding
      case total - 1:
        return 1;
      // otherwise, calculate
      default:
        return this.clamp(parseFloat(postIndex) / total);
    }
  }
  registerScrollarea(element) {
    this.scrollareaElement = element;
  }
  registerScroller(element) {
    this.scrollerElement = element;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showButton", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "current", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "percentage", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this._percentFor(this.args.model, this.args.enteredIndex);
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "total", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "date", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "lastReadPercentage", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "lastRead", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "lastReadTop", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "before", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "after", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "timelineScrollareaStyle", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "dragging", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "excerpt", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "";
  }
}), _applyDecoratedDescriptor(_class.prototype, "calculatePosition", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "calculatePosition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateScrollPosition", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateScrollPosition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePercentage", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "updatePercentage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didStartDrag", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "didStartDrag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragMove", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "dragMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didEndDrag", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "didEndDrag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "postScrolled", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "postScrolled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goBack", [action], Object.getOwnPropertyDescriptor(_class.prototype, "goBack"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerScrollarea", [action], Object.getOwnPropertyDescriptor(_class.prototype, "registerScrollarea"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerScroller", [action], Object.getOwnPropertyDescriptor(_class.prototype, "registerScroller"), _class.prototype)), _class));
export { TopicTimelineScrollArea as default };
export function timelineDate(date) {
  const fmt = date.getFullYear() === new Date().getFullYear() ? "long_no_year_no_time" : "timeline_date";
  return moment(date).format(I18n.t(`dates.${fmt}`));
}
setComponentTemplate(TEMPLATE, TopicTimelineScrollArea);