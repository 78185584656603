import dirSpan from "discourse/helpers/dir-span";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const TopicExcerpt = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if @topic.hasExcerpt}}
    <a href={{@topic.url}} class="topic-excerpt">
      {{dirSpan @topic.escapedExcerpt htmlSafe="true"}}

      {{#if @topic.excerptTruncated}}
        <span class="topic-excerpt-more">{{i18n "read_more"}}</span>
      {{/if}}
    </a>
  {{/if}}

*/
{
  "id": "LOJTbWPr",
  "block": "[[[1,\"\\n\"],[41,[30,1,[\"hasExcerpt\"]],[[[1,\"    \"],[10,3],[15,6,[30,1,[\"url\"]]],[14,0,\"topic-excerpt\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[[30,1,[\"escapedExcerpt\"]]],[[\"htmlSafe\"],[\"true\"]]]],[1,\"\\n\\n\"],[41,[30,1,[\"excerptTruncated\"]],[[[1,\"        \"],[10,1],[14,0,\"topic-excerpt-more\"],[12],[1,[28,[32,1],[\"read_more\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[\"@topic\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/topic-excerpt.js",
  "scope": () => [dirSpan, i18n],
  "isStrictMode": true
}), templateOnly());
export default TopicExcerpt;