define("discourse/plugins/discourse-gamification/discourse/components/period-input", ["exports", "@ember/object", "I18n", "select-kit/components/combo-box", "discourse/plugins/discourse-gamification/discourse/components/gamification-leaderboard"], function (_exports, _object, _I18n, _comboBox, _gamificationLeaderboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    pluginApiIdentifiers: ["period-input"],
    classNames: ["period-input", "period-input"],
    selectKitOptions: {
      filterable: true,
      allowAny: false
    },
    content: (0, _object.computed)(function () {
      let periods = [];
      periods = periods.concat(_gamificationLeaderboard.LEADERBOARD_PERIODS.map((period, index) => ({
        name: _I18n.default.t(`gamification.leaderboard.period.${period}`),
        id: index
      })));
      return periods;
    })
  });
});