import BulkSelectTopicsDropdown from "discourse/components/bulk-select-topics-dropdown";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const TopicBulkSelectDropdown = setComponentTemplate(createTemplateFactory(
/*
  
  <div class="bulk-select-topics-dropdown">
    <span class="bulk-select-topic-dropdown__count">
      {{i18n
        "topics.bulk.selected_count"
        count=@bulkSelectHelper.selected.length
      }}
    </span>
    <BulkSelectTopicsDropdown @bulkSelectHelper={{@bulkSelectHelper}} />
  </div>

*/
{
  "id": "wZHi/9Te",
  "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"bulk-select-topics-dropdown\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"bulk-select-topic-dropdown__count\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"topics.bulk.selected_count\"],[[\"count\"],[[30,1,[\"selected\",\"length\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[32,1],null,[[\"@bulkSelectHelper\"],[[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@bulkSelectHelper\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/topic-bulk-select-dropdown.js",
  "scope": () => [i18n, BulkSelectTopicsDropdown],
  "isStrictMode": true
}), templateOnly());
export default TopicBulkSelectDropdown;