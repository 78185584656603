define("discourse/plugins/discourse-gamification/discourse/helpers/fullnumber", ["exports", "@ember/component/helper", "I18n"], function (_exports, _helper, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.fullnumber = fullnumber;
  function fullnumber(number) {
    return _I18n.default.toNumber(number, {
      precision: 0
    });
  }
  var _default = _exports.default = _helper.default.helper(fullnumber);
});