import icon from "discourse-common/helpers/d-icon";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const ActionList = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}

*/
{
  "id": "b0etsQRf",
  "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[11,0],[24,0,\"post-actions\"],[17,2],[12],[1,\"\\n      \"],[1,[28,[32,0],[[30,3]],null]],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,1]],null]],null],null,[[[1,\"        \"],[10,3],[15,6,[29,[[30,5,[\"url\"]],\"/\",[30,4]]]],[12],[1,\"#\"],[1,[30,4]],[13],[1,\"\\n\"]],[4]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null]],[\"@postNumbers\",\"&attrs\",\"@icon\",\"postNumber\",\"@topic\"],false,[\"if\",\"each\",\"-track-array\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/action-list.js",
  "scope": () => [icon],
  "isStrictMode": true
}), templateOnly());
export default ActionList;