define("discourse/plugins/automation/admin/routes/admin-plugins-discourse-automation-edit", ["exports", "@ember/object", "rsvp", "discourse/lib/ajax", "discourse/routes/discourse", "discourse/plugins/automation/admin/models/discourse-automation-field"], function (_exports, _object, _rsvp, _ajax, _discourse, _discourseAutomationField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let AutomationEdit = _exports.default = (_class = class AutomationEdit extends _discourse.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "controllerName", "admin-plugins-discourse-automation-edit");
    }
    model(params) {
      return (0, _rsvp.hash)({
        scriptables: this.store.findAll("discourse-automation-scriptable").then(result => result.content),
        triggerables: (0, _ajax.ajax)(`/admin/plugins/discourse-automation/triggerables.json?automation_id=${params.id}`).then(result => result ? result.triggerables : []),
        automation: this.store.find("discourse-automation-automation", params.id)
      });
    }
    _fieldsForTarget(automation, target) {
      return (automation[target].templates || []).map(template => {
        const jsonField = automation[target].fields.find(f => f.name === template.name && f.component === template.component);
        return _discourseAutomationField.default.create(template, {
          name: automation[target].id,
          type: target
        }, jsonField);
      });
    }
    setupController(controller, model) {
      const automation = model.automation;
      controller.setProperties({
        model,
        error: null,
        automationForm: {
          name: automation.name,
          enabled: automation.enabled,
          trigger: automation.trigger?.id,
          script: automation.script?.id,
          fields: this._fieldsForTarget(automation, "script").concat(this._fieldsForTarget(automation, "trigger"))
        }
      });
    }
    refreshRoute() {
      return this.refresh();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "refreshRoute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshRoute"), _class.prototype)), _class);
});