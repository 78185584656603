import { on } from "@ember/modifier";
import { htmlSafe } from "@ember/template";
import TopicEntrance from "discourse/components/topic-list/topic-entrance";
import TopicPostBadges from "discourse/components/topic-post-badges";
import TopicStatus from "discourse/components/topic-status";
import formatAge from "discourse/helpers/format-age";
import { modKeysPressed } from "discourse/lib/utilities";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const onTimestampClick = function (event1) {
  if (modKeysPressed(event1).length) {
    // Allow opening the link in a new tab/window
    event1.stopPropagation();
  } else {
    // Otherwise only display the TopicEntrance component
    event1.preventDefault();
  }
};
const FeaturedTopic = setComponentTemplate(createTemplateFactory(
/*
  
  <div data-topic-id={{@topic.id}} class="featured-topic --glimmer">
    <TopicStatus @topic={{@topic}} />

    <a href={{@topic.lastUnreadUrl}} class="title">{{htmlSafe
        @topic.fancyTitle
      }}</a>

    <TopicPostBadges
      @unreadPosts={{@topic.unread_posts}}
      @unseen={{@topic.unseen}}
      @url={{@topic.lastUnreadUrl}}
    />

    <TopicEntrance @topic={{@topic}}>
      <a
        {{on "click" onTimestampClick}}
        href={{@topic.lastPostUrl}}
        class="last-posted-at"
      >{{formatAge @topic.last_posted_at}}</a>
    </TopicEntrance>
  </div>

*/
{
  "id": "dJgHaRqv",
  "block": "[[[1,\"\\n  \"],[10,0],[15,\"data-topic-id\",[30,1,[\"id\"]]],[14,0,\"featured-topic --glimmer\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@topic\"],[[30,1]]],null],[1,\"\\n\\n    \"],[10,3],[15,6,[30,1,[\"lastUnreadUrl\"]]],[14,0,\"title\"],[12],[1,[28,[32,1],[[30,1,[\"fancyTitle\"]]],null]],[13],[1,\"\\n\\n    \"],[8,[32,2],null,[[\"@unreadPosts\",\"@unseen\",\"@url\"],[[30,1,[\"unread_posts\"]],[30,1,[\"unseen\"]],[30,1,[\"lastUnreadUrl\"]]]],null],[1,\"\\n\\n    \"],[8,[32,3],null,[[\"@topic\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[11,3],[16,6,[30,1,[\"lastPostUrl\"]]],[24,0,\"last-posted-at\"],[4,[32,4],[\"click\",[32,5]],null],[12],[1,[28,[32,6],[[30,1,[\"last_posted_at\"]]],null]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@topic\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/featured-topic.js",
  "scope": () => [TopicStatus, htmlSafe, TopicPostBadges, TopicEntrance, on, onTimestampClick, formatAge],
  "isStrictMode": true
}), templateOnly());
export default FeaturedTopic;