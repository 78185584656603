var _NewListHeaderControls;
import Component from "@glimmer/component";
import { fn } from "@ember/helper";
import { on } from "@ember/modifier";
import concatClass from "discourse/helpers/concat-class";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
export default class NewListHeaderControls extends Component {
  get topicsActive() {
    return this.args.current === "topics";
  }
  get repliesActive() {
    return this.args.current === "replies";
  }
  get allActive() {
    return !this.topicsActive && !this.repliesActive;
  }
  get repliesButtonLabel() {
    if (this.args.newRepliesCount > 0) {
      return i18n("filters.new.replies_with_count", {
        count: this.args.newRepliesCount
      });
    } else {
      return i18n("filters.new.replies");
    }
  }
  get topicsButtonLabel() {
    if (this.args.newTopicsCount > 0) {
      return i18n("filters.new.topics_with_count", {
        count: this.args.newTopicsCount
      });
    } else {
      return i18n("filters.new.topics");
    }
  }
  get staticLabel() {
    if (this.args.noStaticLabel || this.args.newTopicsCount > 0 && this.args.newRepliesCount > 0) {
      return;
    }
    if (this.args.newTopicsCount > 0) {
      return this.topicsButtonLabel;
    } else {
      return this.repliesButtonLabel;
    }
  }
}
_NewListHeaderControls = NewListHeaderControls;
setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.staticLabel}}
      <span class="static-label">{{this.staticLabel}}</span>
    {{else}}
      <button
        {{on "click" (fn @changeNewListSubset null)}}
        class={{concatClass
          "topics-replies-toggle --all"
          (if this.allActive "active")
        }}
      >
        {{i18n "filters.new.all"}}
      </button>

      <button
        {{on "click" (fn @changeNewListSubset "topics")}}
        class={{concatClass
          "topics-replies-toggle --topics"
          (if this.topicsActive "active")
        }}
      >
        {{this.topicsButtonLabel}}
      </button>

      <button
        {{on "click" (fn @changeNewListSubset "replies")}}
        class={{concatClass
          "topics-replies-toggle --replies"
          (if this.repliesActive "active")
        }}
      >
        {{this.repliesButtonLabel}}
      </button>
    {{/if}}
  
*/
{
  "id": "ZkiV61Jr",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"staticLabel\"]],[[[1,\"      \"],[10,1],[14,0,\"static-label\"],[12],[1,[30,0,[\"staticLabel\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,\"button\"],[16,0,[28,[32,0],[\"topics-replies-toggle --all\",[52,[30,0,[\"allActive\"]],\"active\"]],null]],[4,[32,1],[\"click\",[28,[32,2],[[30,1],null],null]],null],[12],[1,\"\\n        \"],[1,[28,[32,3],[\"filters.new.all\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[11,\"button\"],[16,0,[28,[32,0],[\"topics-replies-toggle --topics\",[52,[30,0,[\"topicsActive\"]],\"active\"]],null]],[4,[32,1],[\"click\",[28,[32,2],[[30,1],\"topics\"],null]],null],[12],[1,\"\\n        \"],[1,[30,0,[\"topicsButtonLabel\"]]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[11,\"button\"],[16,0,[28,[32,0],[\"topics-replies-toggle --replies\",[52,[30,0,[\"repliesActive\"]],\"active\"]],null]],[4,[32,1],[\"click\",[28,[32,2],[[30,1],\"replies\"],null]],null],[12],[1,\"\\n        \"],[1,[30,0,[\"repliesButtonLabel\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@changeNewListSubset\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/new-list-header-controls.js",
  "scope": () => [concatClass, on, fn, i18n],
  "isStrictMode": true
}), _NewListHeaderControls);