define("discourse/plugins/automation/admin/components/placeholders-list", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _PlaceholdersList;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  let PlaceholdersList = _exports.default = (_class = (_PlaceholdersList = class PlaceholdersList extends _component.default {
    copyPlaceholder(placeholder1) {
      this.args.onCopy(`${this.args.currentValue || ""}{{${placeholder1}}}`);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div class="placeholders-list">
        {{#each @placeholders as |placeholder|}}
          <DButton
            @translatedLabel={{placeholder}}
            class="placeholder-item"
            @action={{fn this.copyPlaceholder placeholder}}
          />
        {{/each}}
      </div>
    
  */
  {
    "id": "nlyIXRZV",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"placeholders-list\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"        \"],[8,[32,0],[[24,0,\"placeholder-item\"]],[[\"@translatedLabel\",\"@action\"],[[30,2],[28,[32,1],[[30,0,[\"copyPlaceholder\"]],[30,2]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@placeholders\",\"placeholder\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/placeholders-list.js",
    "scope": () => [_dButton.default, _helper.fn],
    "isStrictMode": true
  }), _PlaceholdersList), _PlaceholdersList), (_applyDecoratedDescriptor(_class.prototype, "copyPlaceholder", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "copyPlaceholder"), _class.prototype)), _class);
});