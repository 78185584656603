define("discourse/plugins/automation/admin/components/fields/da-email-group-user-field", ["exports", "@glimmer/tracking", "@ember/helper", "@ember/object", "select-kit/components/email-group-user-chooser", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/component", "@ember/template-factory"], function (_exports, _tracking, _helper, _object, _emailGroupUserChooser, _daBaseField, _daFieldDescription, _daFieldLabel, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _EmailGroupUserField;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let EmailGroupUserField = _exports.default = (_class = (_EmailGroupUserField = class EmailGroupUserField extends _daBaseField.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "recipients", _descriptor, this);
      _initializerDefineProperty(this, "groups", _descriptor2, this);
    }
    updateRecipients(selected1, content1) {
      const newGroups1 = content1.filterBy("isGroup").mapBy("id");
      this._updateGroups(selected1, newGroups1);
      this.recipients = selected1.join(",");
    }
    _updateGroups(selected1, newGroups1) {
      const groups1 = [];
      this.groups.forEach(existing1 => {
        if (selected1.includes(existing1)) {
          groups1.addObject(existing1);
        }
      });
      newGroups1.forEach(newGroup1 => {
        if (!groups1.includes(newGroup1)) {
          groups1.addObject(newGroup1);
        }
      });
      this.groups = groups1;
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field email-group-user-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <EmailGroupUserChooser
              @value={{@field.metadata.value}}
              @onChange={{this.mutValue}}
              @options={{hash
                includeGroups=true
                includeMessageableGroups=true
                allowEmails=true
                autoWrap=true
                disabled=@field.isDisabled
              }}
            />
  
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      </section>
    
  */
  {
    "id": "TGAHAnZg",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field email-group-user-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@value\",\"@onChange\",\"@options\"],[[30,2,[\"metadata\",\"value\"]],[30,0,[\"mutValue\"]],[28,[32,2],null,[[\"includeGroups\",\"includeMessageableGroups\",\"allowEmails\",\"autoWrap\",\"disabled\"],[true,true,true,true,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-email-group-user-field.js",
    "scope": () => [_daFieldLabel.default, _emailGroupUserChooser.default, _helper.hash, _daFieldDescription.default],
    "isStrictMode": true
  }), _EmailGroupUserField), _EmailGroupUserField), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "recipients", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "groups", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateRecipients", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateRecipients"), _class.prototype)), _class);
});