import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _ActivityColumn;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import PluginOutlet from "discourse/components/plugin-outlet";
import coldAgeClass from "discourse/helpers/cold-age-class";
import concatClass from "discourse/helpers/concat-class";
import element from "discourse/helpers/element";
import formatDate from "discourse/helpers/format-date";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let ActivityColumn = (_class = (_ActivityColumn = class ActivityColumn extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
  }
  get wrapperElement() {
    return element(this.args.tagName ?? "td");
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <this.wrapperElement
      title={{htmlSafe @topic.bumpedAtTitle}}
      class={{concatClass
        "activity"
        (coldAgeClass @topic.createdAt startDate=@topic.bumpedAt class="")
      }}
      ...attributes
    >
      <a
        href={{@topic.lastPostUrl}}
        class="post-activity"
      >{{! no whitespace
        }}<PluginOutlet
          @name="topic-list-before-relative-date"
        />
        {{~formatDate @topic.bumpedAt format="tiny" noTitle="true"~}}
      </a>
    </this.wrapperElement>
  
*/
{
  "id": "euVj51m9",
  "block": "[[[1,\"\\n    \"],[8,[30,0,[\"wrapperElement\"]],[[16,\"title\",[28,[32,0],[[30,1,[\"bumpedAtTitle\"]]],null]],[16,0,[28,[32,1],[\"activity\",[28,[32,2],[[30,1,[\"createdAt\"]]],[[\"startDate\",\"class\"],[[30,1,[\"bumpedAt\"]],\"\"]]]],null]],[17,2]],null,[[\"default\"],[[[[1,\"\\n      \"],[10,3],[15,6,[30,1,[\"lastPostUrl\"]]],[14,0,\"post-activity\"],[12],[8,[32,3],null,[[\"@name\"],[\"topic-list-before-relative-date\"]],null],[1,[28,[32,4],[[30,1,[\"bumpedAt\"]]],[[\"format\",\"noTitle\"],[\"tiny\",\"true\"]]]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@topic\",\"&attrs\"],false,[]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/activity-column.js",
  "scope": () => [htmlSafe, concatClass, coldAgeClass, PluginOutlet, formatDate],
  "isStrictMode": true
}), _ActivityColumn), _ActivityColumn), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { ActivityColumn as default };