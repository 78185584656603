import { and } from "truth-helpers";
import PostsCountColumn from "discourse/components/topic-list/posts-count-column";
import TopicPostBadges from "discourse/components/topic-post-badges";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
const PostCountOrBadges = setComponentTemplate(createTemplateFactory(
/*
  
  {{#if (and @postBadgesEnabled @topic.unread_posts)}}
    <TopicPostBadges
      @unreadPosts={{@topic.unread_posts}}
      @unseen={{@topic.unseen}}
      @url={{@topic.lastUnreadUrl}}
    />
  {{else}}
    <PostsCountColumn @topic={{@topic}} @tagName="div" />
  {{/if}}

*/
{
  "id": "/EQB/oEZ",
  "block": "[[[1,\"\\n\"],[41,[28,[32,0],[[30,1],[30,2,[\"unread_posts\"]]],null],[[[1,\"    \"],[8,[32,1],null,[[\"@unreadPosts\",\"@unseen\",\"@url\"],[[30,2,[\"unread_posts\"]],[30,2,[\"unseen\"]],[30,2,[\"lastUnreadUrl\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[32,2],null,[[\"@topic\",\"@tagName\"],[[30,2],\"div\"]],null],[1,\"\\n\"]],[]]]],[\"@postBadgesEnabled\",\"@topic\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/topic-list/post-count-or-badges.js",
  "scope": () => [and, TopicPostBadges, PostsCountColumn],
  "isStrictMode": true
}), templateOnly());
export default PostCountOrBadges;